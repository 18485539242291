import { useMemo } from 'react';

import { useContentfulPage } from 'hooks/useContentfulPage';
import { useContentfulLocale } from 'hooks/useContentfulLocale';
import { useGatsbyImage } from 'hooks/useGatsbyImage';

import { BlogCallout } from 'components/BlogCallout';
import { HelpLink, SignupLink, BlogLink, GatsbyLink } from 'components/Links';
import { Accordion } from 'components/Accordion';
import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { Cards } from 'components/Cards';
import {
  Container,
  HeroContainer,
  Section,
  SectionImage,
  SectionVideo,
} from 'components/Container';
import { TrustPilot } from 'components/TrustPilot';
import { CTANotice } from 'components/Notice';
import { SupportedBrokersBlock } from 'components/SupportedBrokersBlock';

import videoThumbnail from 'images/dividend-tracker/matilda-youtube-thumbnail.png';
import CheckReport from 'images/dividend-tracker/check-report';
import PieChart from 'images/dividend-tracker/pie-chart';
import Clock from 'images/dividend-tracker/time';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

const DividendTracker: FC<LocalizedPageProps> = () => {
  const {
    translations: { builtForInvestors, supportedMarkets, trackedInvestments, stock, mutualFund },
  } = useContentfulLocale();

  const {
    cards,
    translations: { calculateIncomeText },
    seoProps,
  } = useContentfulPage();

  const taxCards = useMemo(
    () => cards?.filter(card => card.cardIdentifier.startsWith('tax-')),
    [cards]
  );

  const dividendsFAQ = useMemo(
    () => [
      {
        question: 'What is a dividend tracker?',
        answer: (
          <p>
            With Sharesight's dividend tracker software, dividend payments are automatically tracked
            in an investor's portfolio. Investors can view their dividend income and dividend
            history at any time, see the impact of dividend yield on their returns, and track
            dividend reinvestment plans (DRPs) in their portfolio. Additional features include the
            ability to see upcoming dividend income with Sharesight's{' '}
            <BlogLink to="how-to-see-upcoming-dividends-with-sharesight">
              Future Income Report
            </BlogLink>
            , and opt-in to dividend alerts about upcoming announced dividends.
          </p>
        ),
      },
      {
        question: 'How do I track my dividends?',
        answer: (
          <>
            <p>
              To track your dividends, first you will need to <SignupLink>sign up</SignupLink> for a
              Sharesight account. Once you have a Sharesight account, you can start adding trades to
              your portfolio by automatically forwarding{' '}
              <BlogLink to="automatically-import-trades-to-your-sharesight-portfolio-using-email">
                trade confirmation emails
              </BlogLink>{' '}
              from your brokerage account, uploading a{' '}
              <HelpLink to="/import_bulk_trades">spreadsheet file</HelpLink> filled with trades, or
              entering your trades manually.
            </p>
            <p>
              If you track dividend paying {stock}s in your portfolio, dividends and distributions
              will be automatically recorded. All you will need to do is confirm the dividend
              payments in your portfolio. This can be done by clicking into any holding that has an
              orange icon next to it (which signifies that you have unconfirmed transactions) and
              clicking 'confirm automatic transaction'. Sharesight will automatically pull dividend
              income data such as tax credits, exchange rates and foreign source income, however to
              ensure accuracy it is recommended that you verify these figures using your paper or
              digital dividend statements.
            </p>
          </>
        ),
      },
      {
        question: 'How to make a dividend portfolio',
        answer: (
          <>
            <p>
              It's easy to track dividend paying {stock}s along with the rest of your investments in
              Sharesight. However, if you would like to create a separate portfolio for your
              dividend {stock}s, this can be done with Sharesight's{' '}
              <GatsbyLink to="/pricing">Investor or Expert plan</GatsbyLink>, which allows users to
              have multiple portfolios.
            </p>

            <p>
              Alternatively, you may wish to apply an asset allocation framework to a singular
              portfolio, which can be done using Sharesight's{' '}
              <BlogLink to="how-to-track-your-asset-allocation-strategy-in-sharesight">
                Custom Groups feature
              </BlogLink>
              . Available on all Sharesight plans, this feature allows investors to group their
              investments by 'themes' in their portfolio.
            </p>
          </>
        ),
      },
      {
        question: 'How do I grow a dividend portfolio?',
        answer: (
          <>
            <p>
              Sharesight has a number of useful features designed to help investors reach their
              investment goals, including developing a dividend growth portfolio. For example,
              Sharesight users can easily view the dividend performance of {stock}s in their
              portfolio by seeing each {stock}'s overall dividend contribution on the Portfolio
              Overview page, or by clicking into individual {stock}s to see how much dividends
              contributed to the {stock}'s net total return.
            </p>
            <p>
              Sharesight's{' '}
              <BlogLink to="how-to-track-a-dividend-reinvestment-plan">
                Auto Dividend Reinvestment feature
              </BlogLink>{' '}
              (available for ASX and NZX {stock}s) is another useful feature that helps investors
              track their dividend reinvestment plans (DRPs), which is one key measure that many
              investors take to grow their dividend portfolio. Dividend reinvestments can be tracked
              for non-ASX and NZX {stock}s as well, simply by manually recording the dividend
              reinvestment when you add or edit a dividend payment in your portfolio. Investors
              searching for prospective dividend {stock}s will also benefit from Sharesight's{' '}
              <BlogLink to="quickly-view-the-performance-of-any-stock-with-the-share-checker">
                Share Checker tool
              </BlogLink>
              , which allows investors to quickly view the performance of any {stock}, inclusive of
              dividends.
            </p>
          </>
        ),
      },
      {
        question: 'What are the benefits of a dividend portfolio tracker?',
        answer: (
          <p>
            One of the main benefits of Sharesight's dividend tracker is that it automatically
            tracks dividend income throughout the year. With all of their dividend information in
            one place, investors spend less time on tedious portfolio admin (especially at tax time)
            – leaving them with more time to spend on their investment decisions. Other benefits
            include the ability to see upcoming dividend income using Sharesight's{' '}
            <BlogLink to="how-to-see-upcoming-dividends-with-sharesight">
              Future Income Report
            </BlogLink>
            , which helps investors plan their cash flow, along with the ability to track the impact
            of{' '}
            <BlogLink to="how-to-track-a-dividend-reinvestment-plan">
              dividend reinvestment plans
            </BlogLink>{' '}
            (DRPs).
          </p>
        ),
      },
      {
        question: "Who is Sharesight's dividend tracker dividend tracker suitable for?",
        answer: (
          <p>
            Sharesight's dividend payout tracker is suitable for all investors, whether their
            portfolio consists of dividend paying {stock}s or not. This is because with Sharesight,
            investors can track the price and performance of more than {trackedInvestments} {stock}
            s, ETFs and funds in over{' '}
            <GatsbyLink to="faq/#question-which-stock-exchanges-does-sharesight-support">
              {supportedMarkets} global markets
            </GatsbyLink>{' '}
            – giving investors around the world access to Sharesight's award-winning performance,
            dividend tracking and{' '}
            <GatsbyLink to="investment-portfolio-tax">tax reporting</GatsbyLink> features.
          </p>
        ),
      },
      {
        question: "Why choose Sharesight's dividend portfolio tracker?",
        answer: (
          <>
            <p>
              With the ability to automatically track dividends and see the impact of dividends on
              your returns, Sharesight is the best free dividend tracker for self-directed
              investors. As a comprehensive online portfolio tracking solution, Sharesight also has
              a range of powerful features that extend beyond dividend tracking. For example, unlike
              the data provided by most brokers or basic portfolio trackers such as Yahoo Finance,
              Sharesight takes into account the impact of capital gains, dividends, brokerage fees
              and foreign exchange rates when calculating returns – providing investors with the
              full picture of their portfolio's performance.
            </p>
            <p>
              Investors will also benefit from Sharesight's suite of advanced reports built for
              self-directed investors, including{' '}
              <HelpLink to="/performance_report/">Performance</HelpLink>,{' '}
              <HelpLink to="/diversity_report/">Portfolio Diversity</HelpLink>,{' '}
              <BlogLink to="explore-your-portfolio-performance-with-the-contribution-analysis-report">
                Contribution Analysis
              </BlogLink>
              ,{' '}
              <BlogLink to="value-your-investments-in-any-currency-with-sharesight">
                Multi-Currency Valuation
              </BlogLink>{' '}
              and{' '}
              <BlogLink to="how-to-see-upcoming-dividends-with-sharesight">
                Future Income to see your upcoming dividends
              </BlogLink>
              . The ability to track{' '}
              <BlogLink to="cash-accounts-now-sync-transactions-with-your-portfolio">
                cash accounts
              </BlogLink>
              , <BlogLink to="how-to-track-an-investment-property-in-sharesight">property</BlogLink>{' '}
              and even{' '}
              <BlogLink to="track-bitcoin-and-other-cryptocurrencies-with-sharesight">
                cryptocurrency
              </BlogLink>{' '}
              is just another reason that investors should consider using Sharesight to track their
              investment portfolio.
            </p>
          </>
        ),
      },
    ],
    [supportedMarkets, trackedInvestments, stock]
  );

  const dividendCards = useMemo(
    () => [
      {
        cardIdentifier: 'track-1',
        image: <CheckReport />,
        title: 'Track your dividend income',
        text: (
          <>
            See all your dividends and distributions, across multiple brokers and more than{' '}
            <GatsbyLink to="supported-stock-exchanges-managed-funds-mutual-funds">
              {supportedMarkets} exchanges
            </GatsbyLink>
            .
          </>
        ),
      },
      {
        cardIdentifier: 'track-2',
        image: <PieChart />,
        title: 'See your dividend yield',
        text: 'Calculate the impact that dividends are having on your portfolio performance.',
      },
      {
        cardIdentifier: 'track-3',
        image: <Clock />,
        title: 'Make tax time a breeze',
        text: 'Save time and money this tax season with all your dividend data living in one place.',
      },
    ],
    [supportedMarkets]
  );

  return (
    <Layout>
      <Seo title={seoProps.rawTitle} />

      <HeroContainer>
        <Section>
          <h1>
            Simplify your
            <br />
            dividend tracking
          </h1>

          <p>
            Track dividends and distributions from over {trackedInvestments} global {stock}s, ETFs{' '}
            and {mutualFund}s, going back up to 20 years.
          </p>

          <br />
          <SignupLink asButton />
        </Section>

        <SectionImage
          image={useGatsbyImage({
            name: 'dividend-tracker/hero',
            alt: 'Simplify your dividend tracking',
            loading: 'eager',
          })}
          boxShadow={false}
        />
      </HeroContainer>

      <Cards
        header="Automatically track all your dividends"
        verticalAlign="top"
        cards={dividendCards}
      />
      <SupportedBrokersBlock />

      <CTANotice />

      <Container id="mathildes-customer-story">
        <Section>
          <h2>"It does everything that I need and it does it automatically."</h2>
        </Section>

        <SectionVideo videoId="fuQua22Wkro" thumbnailUrl={videoThumbnail} />
      </Container>

      <Container id="see-your-entire-dividend-history">
        <Section>
          <h2>
            See your entire
            <br />
            dividend history
          </h2>

          <p>
            Import your historical trades, then sit back and watch as your dividend income is
            automatically tracked – going back up to 20 years.
          </p>
        </Section>

        <SectionImage
          image={useGatsbyImage({
            name: 'dividend-tracker/history',
            alt: 'See your entire dividend history',
          })}
          boxShadow={false}
        />
      </Container>

      <Container id="understand-your-dividend-yield">
        <Section>
          <h2>
            Understand
            <br />
            your dividend yield
          </h2>

          <p>
            See the impact of dividends on your portfolio performance, both at the portfolio and
            individual holding level.
          </p>
          <p>
            Your dividends are factored alongside {stock} price, brokerage fees, and currency
            fluctuations to give you a true understanding of your total return.
          </p>
        </Section>

        <SectionImage
          image={useGatsbyImage({
            name: 'dividend-tracker/understand',
            alt: 'Understand your dividend yield',
          })}
          boxShadow={false}
        />
      </Container>

      <Container id="track-reinvested-dividends">
        <Section>
          <h2>Track reinvested dividends</h2>

          <p>
            Easily track your dividend reinvestment plans (DRPs/DRIPs) and their impact on your
            portfolio performance and return.
          </p>
        </Section>

        <SectionImage
          image={useGatsbyImage({
            name: 'dividend-tracker/track',
            alt: 'Track your reinvested dividends: DRPs/DRIPs',
          })}
          boxShadow={false}
        />
      </Container>

      <Container id="never-miss-a-dividend">
        <Section>
          <h2>Never miss a dividend</h2>

          <p>
            See upcoming dividends as soon as they are announced to the market with the actual
            income predicted based on your current portfolio positions. Plus, you can opt-in to
            email alerts to be notified as dividends are announced.
          </p>
        </Section>

        <SectionImage
          image={useGatsbyImage({
            name: 'dividend-tracker/never-miss',
            alt: "See upcoming dividends as soon as they're announced.",
          })}
          boxShadow={false}
        />
      </Container>

      <Container id="calculate-your-dividend-income">
        <Section>
          <h2>
            Calculate your
            <br />
            dividend income
          </h2>

          <p>{calculateIncomeText}</p>

          <p>
            Export the report to PDF, Excel or Google Sheets, or share secure access with your
            accountant.
          </p>
        </Section>

        <SectionImage
          image={useGatsbyImage({
            name: 'dividend-tracker/calculate',
            alt: 'Calculate your dividend income',
          })}
          boxShadow={false}
        />
      </Container>

      <BlogCallout
        slug="3-reasons-why-you-need-to-be-tracking-dividends"
        title="Learn more about dividends"
        description="Learn more about how to track dividends using Sharesight."
        linkText="Read dividend article"
      />

      <Cards cards={taxCards} header={builtForInvestors} />

      <CTANotice />

      <TrustPilot tagId="dividends" localizeTag={false} />

      <Accordion title="Dividend tracker FAQ" details={dividendsFAQ} injectFAQStructuredData />
    </Layout>
  );
};

export default DividendTracker;
