const SVGTime = () => (
  <svg
    width="108"
    height="108"
    viewBox="0 0 108 108"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Graphic illustration of a clock</title>
    <path
      d="M53.9594 96.9189C77.6853 96.9189 96.9189 77.6853 96.9189 53.9594C96.9189 30.2336 77.6853 11 53.9594 11C30.2336 11 11 30.2336 11 53.9594C11 77.6853 30.2336 96.9189 53.9594 96.9189Z"
      fill="#FD7D35"
    />
    <path
      d="M53.569 82.1379C69.3472 82.1379 82.1379 69.3472 82.1379 53.569C82.1379 37.7908 69.3472 25 53.569 25C37.7908 25 25 37.7908 25 53.569C25 69.3472 37.7908 82.1379 53.569 82.1379Z"
      fill="white"
    />
    <path
      d="M51.4659 43.4688L55 54.4999L35.9658 63.669"
      stroke="#2839D7"
      strokeWidth="5"
      strokeLinecap="square"
    />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGTime;
